/** Information Modal **/

.information-modal {
  @apply absolute bg-black/70 inset-0 w-full h-screen opacity-0 invisible flex items-center;
  transition: opacity .3s, visibility 0s .3s;
  z-index: 10000;

  /* Information Modal is opened */
  &.modal-opened {
    @apply opacity-100 visible;
    transition: opacity .3s;
  }

  /* InformationModal container */
  .information-modal-container {
    @apply relative m-auto w-1/2;
    max-width: 450px;
    min-width: 290px;

    /* InformationModal content */
    .information-modal-content {
      @apply flex flex-col bg-white text-base p-4;
    }
  }
}