@media (max-width: 540px) {
  /* Icon size */
  .icon-2xs {
    font-size: 3px;
  }
  .icon-xs {
    font-size: 6px;
  }
  .icon-sm {
    font-size: 9px;
  }
  .icon-md {
    font-size: 12px;
  }
  .icon-lg {
    font-size: 16px;
  }
  .icon-xl {
    font-size: 24px;
  }
  .icon-2xl {
    font-size: 32px;
  }
  .icon-3xl {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  /* Icon size */
  .icon-2xs {
    font-size: 4px;
  }
  .icon-xs {
    font-size: 8px;
  }
  .icon-sm {
    font-size: 12px;
  }
  .icon-md {
    font-size: 16px;
  }
  .icon-lg {
    font-size: 24px;
  }
  .icon-xl {
    font-size: 32px;
  }
  .icon-2xl {
    font-size: 48px;
  }
  .icon-3xl {
    font-size: 64px;
  }
}