/** Action Modal Footer **/

.action-modal-footer {
  @apply flex flex-row justify-center;

  .action-button {
    @apply text-center rounded-full font-medium py-4 px-8 cursor-pointer mx-2 uppercase transition-all duration-250
           ease-in-out outline-0;

    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);

    &:hover {
      transform: translateY(-4px);
      //transform: scale(1.1);
    }

    &:disabled {
      @apply cursor-not-allowed opacity-70;

      &:hover {
        @apply transform-none;
      }
    }

    &.action-button-confirm {
      @apply bg-white text-modal-header;
    }

    &.action-button-cancel {
      @apply bg-status-error text-white;
    }
  }
}