/* InformationModal header */

.information-modal-header {
  @apply text-white bg-modal-header flex flex-row py-2 px-3;

  .information-modal-title {
    @apply p-4 text-3xl font-semibold w-full text-left truncate capitalize;
  }

  .information-modal-close-button {
    @apply my-auto mr-4 cursor-pointer;
  }
}