@media (max-width: 540px) and (orientation: portrait) {
  .information-modal-header {
    @apply py-1 px-2;

    .information-modal-title {
      @apply text-xl;
    }

    .information-modal-close-button {
    }
  }
}