/** Icon **/

.icon {
  @apply transition-colors duration-250;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'devicons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Icon content */

.icon-expo-go:before {
  content: "\e91e";
}
.icon-arobase:before {
  content: "\e900";
}
.icon-lock:before {
  content: "\e901";
}
.icon-login:before {
  content: "\e902";
}
.icon-shield:before {
  content: "\e903";
}
.icon-user:before {
  content: "\e904";
}
.icon-quote-right:before {
  content: "\e916";
}
.icon-quote-left:before {
  content: "\e917";
}
.icon-folder-back:before {
  content: "\e914";
}
.icon-folder-cover:before {
  content: "\e915";
}
.icon-amp:before {
  content: "\e90c";
}
.icon-typescript:before {
  content: "\e90b";
}
.icon-ovh:before {
  content: "\e906";
}
.icon-aws:before {
  content: "\e907";
}
.icon-azure:before {
  content: "\e909";
}
.icon-digitalocean:before {
  content: "\e90a";
}
.icon-elasticsearch:before {
  content: "\e905";
}
.icon-nuget:before {
  content: "\e908";
}
.icon-mobx:before {
  content: "\e90d";
}
.icon-mail:before {
  content: "\e90e";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-heart:before {
  content: "\e910";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-music:before {
  content: "\e911";
}
.icon-travel:before {
  content: "\e9af";
}
.icon-globe:before {
  content: "\e9ca";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-sequelize:before {
  content: "\e912";
}
.icon-postgresql:before {
  content: "\e66e";
}
.icon-tailwindcss:before {
  content: "\e913";
}
.icon-redis:before {
  content: "\e920";
}
.icon-memcached:before {
  content: "\e92c";
}
.icon-solr:before {
  content: "\e937";
}
.icon-nodejs:before {
  content: "\e928";
}
.icon-share:before {
  content: "\e081";
}
.icon-cross:before {
  content: "\e117";
}
.icon-download:before {
  content: "\e122";
}
.icon-git:before {
  content: "\e602";
}
.icon-bitbucket:before {
  content: "\e603";
}
.icon-mysql:before {
  content: "\e604";
}
.icon-dropbox:before {
  content: "\e607";
}
.icon-github_badge:before {
  content: "\e609";
}
.icon-android:before {
  content: "\e60e";
}
.icon-linux:before {
  content: "\e612";
}
.icon-github_full:before {
  content: "\e617";
}
.icon-npm:before {
  content: "\e61e";
}
.icon-raspberry_pi:before {
  content: "\e622";
}
.icon-codrops:before {
  content: "\e62f";
}
.icon-google_drive:before {
  content: "\e631";
}
.icon-html5:before {
  content: "\e636";
}
.icon-java:before {
  content: "\e638";
}
.icon-ubuntu:before {
  content: "\e63a";
}
.icon-php:before {
  content: "\e63d";
}
.icon-joomla:before {
  content: "\e641";
}
.icon-css3:before {
  content: "\e649";
}
.icon-javascript:before {
  content: "\e64f";
}
.icon-jquery:before {
  content: "\e650";
}
.icon-w3c:before {
  content: "\e66c";
}
.icon-msql_server:before {
  content: "\e67c";
}
.icon-nestjs:before {
  content: "\e91d";
}
.icon-nextjs:before {
  content: "\e923";
}
.icon-debian:before {
  content: "\e67d";
}
.icon-dotnet:before {
  content: "\e67f";
}
.icon-terminal:before {
  content: "\e695";
}
.icon-react:before {
  content: "\e929";
}
.icon-ansible:before {
  content: "\e92a";
}
.icon-materiald:before {
  content: "\e92b";
}
.icon-neo4j:before {
  content: "\e92d";
}
.icon-retrofit:before {
  content: "\e92e";
}
.icon-rxjava:before {
  content: "\e92f";
}
.icon-vertx:before {
  content: "\e930";
}
.icon-hashicorp-vagrant:before {
  content: "\e931";
}
.icon-androidstudio:before {
  content: "\e932";
}
.icon-googleplus:before {
  content: "\e933";
}
.icon-gradle:before {
  content: "\e934";
}
.icon-jetbrains:before {
  content: "\e935";
}
.icon-linkedin:before {
  content: "\e936";
}
.icon-twitter:before {
  content: "\e938";
}
.icon-vs:before {
  content: "\e939";
}
.icon-help:before {
  content: "\e93a";
}
.icon-github:before {
  content: "\eab0";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-docker:before {
  content: "\e918";
}
.icon-cheveron-down:before {
  content: "\e919";
}
.icon-cheveron-left:before {
  content: "\e91a";
}
.icon-cheveron-right:before {
  content: "\e91b";
}
.icon-cheveron-up:before {
  content: "\e91c";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}

/* Icon size */
.icon-2xs {
  font-size: 8px;
}
.icon-xs {
  font-size: 16px;
}
.icon-sm {
  font-size: 24px;
}
.icon-md {
  font-size: 32px;
}
.icon-lg {
  font-size: 48px;
}
.icon-xl {
  font-size: 64px;
}
.icon-2xl {
  font-size: 96px;
}
.icon-3xl {
  font-size: 128px;
}

/* Icon color */

.icon-color-current {
  @apply text-current;
}
.icon-color-black {
  @apply text-black;
}
.icon-color-white {
  @apply text-white;
}
.icon-color-modal-header {
  @apply text-modal-header;
}
.icon-color-modal-footer {
  @apply text-modal-footer;
}
.icon-color-brand-facebook {
  @apply text-brand-facebook;
}
.icon-color-brand-googleplus {
  @apply text-brand-googleplus;
}
.icon-color-brand-linkedin {
  @apply text-brand-linkedin;
}
.icon-color-brand-twitter {
  @apply text-brand-twitter;
}
.icon-color-brand-github {
  @apply text-brand-github;
}
.icon-color-brand-youtube {
  @apply text-brand-youtube;
}
.icon-color-brand-react {
  @apply text-brand-react;
}
.icon-color-brand-codrops {
  @apply text-brand-codrops;
}
.icon-color-section-about {
  @apply text-section-about;
}
.icon-color-section-exps {
  @apply text-section-exps;
}
.icon-color-section-skills {
  @apply text-section-skills;
}
.icon-color-section-projects {
  @apply text-section-projects;
}
.icon-color-section-passions {
  @apply text-section-passions;
}
.icon-color-section-contact {
  @apply text-section-contact;
}

/* Icon color on hover */

.icon-hover-color-current:hover {
  @apply text-current;
}
.icon-hover-color-black:hover {
  @apply text-black;
}
.icon-hover-color-white:hover {
  @apply text-white;
}
.icon-hover-color-modal-header:hover {
  @apply text-modal-header;
}
.icon-hover-color-modal-footer:hover {
  @apply text-modal-footer;
}
.icon-hover-color-brand-facebook:hover {
  @apply text-brand-facebook;
}
.icon-hover-color-brand-googleplus:hover {
  @apply text-brand-googleplus;
}
.icon-hover-color-brand-linkedin:hover {
  @apply text-brand-linkedin;
}
.icon-hover-color-brand-twitter:hover {
  @apply text-brand-twitter;
}
.icon-hover-color-brand-github:hover {
  @apply text-brand-github;
}
.icon-hover-color-brand-youtube:hover {
  @apply text-brand-youtube;
}
.icon-hover-color-brand-react:hover {
  @apply text-brand-react;
}
.icon-hover-color-brand-codrops:hover {
  @apply text-brand-codrops;
}
.icon-hover-color-section-about:hover {
  @apply text-section-about;
}
.icon-hover-color-section-exps:hover {
  @apply text-section-exps;
}
.icon-hover-color-section-skills:hover {
  @apply text-section-skills;
}
.icon-hover-color-section-projects:hover {
  @apply text-section-projects;
}
.icon-hover-color-section-passions:hover {
  @apply text-section-passions;
}
.icon-hover-color-section-contact:hover {
  @apply text-section-contact;
}