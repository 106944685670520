/** Circled Icon **/

.circled-icon {
  @apply flex justify-center items-center h-fit w-fit transition-colors duration-250 border-1 p-6 rounded-full;
}

/* Background */

.circled-icon-bg-current {
  @apply bg-current;
}
.circled-icon-bg-transparent {
  @apply bg-transparent;
}
.circled-icon-bg-black {
  @apply bg-black;
}
.circled-icon-bg-white {
  @apply bg-white;
}
.circled-icon-bg-modal-header {
  @apply bg-modal-header;
}
.circled-icon-bg-modal-footer {
  @apply bg-modal-footer;
}
.circled-icon-bg-brand-facebook {
  @apply bg-brand-facebook;
}
.circled-icon-bg-brand-googleplus {
  @apply bg-brand-googleplus;
}
.circled-icon-bg-brand-linkedin {
  @apply bg-brand-linkedin;
}
.circled-icon-bg-brand-twitter {
  @apply bg-brand-twitter;
}
.circled-icon-bg-brand-github {
  @apply bg-brand-github;
}
.circled-icon-bg-brand-youtube {
  @apply bg-brand-youtube;
}
.circled-icon-bg-brand-react {
  @apply bg-brand-react;
}
.circled-icon-bg-brand-codrops {
  @apply bg-brand-codrops;
}
.circled-icon-bg-section-about {
  @apply bg-section-about;
}
.circled-icon-bg-section-exps {
  @apply bg-section-exps;
}
.circled-icon-bg-section-skills {
  @apply bg-section-skills;
}
.circled-icon-bg-section-projects {
  @apply bg-section-projects;
}
.circled-icon-bg-section-passions {
  @apply bg-section-passions;
}
.circled-icon-bg-section-contact {
  @apply bg-section-contact;
}

/* Background on hover */

.circled-icon-hover-bg-current:hover {
  @apply bg-current;
}
.circled-icon-hover-bg-transparent:hover {
  @apply bg-transparent;
}
.circled-icon-hover-bg-black:hover {
  @apply bg-black;
}
.circled-icon-hover-bg-white:hover {
  @apply bg-white;
}
.circled-icon-hover-bg-modal-header:hover {
  @apply bg-modal-header;
}
.circled-icon-hover-bg-modal-footer:hover {
  @apply bg-modal-footer;
}
.circled-icon-hover-bg-brand-facebook:hover {
  @apply bg-brand-facebook;
}
.circled-icon-hover-bg-brand-googleplus:hover {
  @apply bg-brand-googleplus;
}
.circled-icon-hover-bg-brand-linkedin:hover {
  @apply bg-brand-linkedin;
}
.circled-icon-hover-bg-brand-twitter:hover {
  @apply bg-brand-twitter;
}
.circled-icon-hover-bg-brand-github:hover {
  @apply bg-brand-github;
}
.circled-icon-hover-bg-brand-youtube:hover {
  @apply bg-brand-youtube;
}
.circled-icon-hover-bg-brand-react:hover {
  @apply bg-brand-react;
}
.circled-icon-hover-bg-brand-codrops:hover {
  @apply bg-brand-codrops;
}
.circled-icon-hover-bg-section-about:hover {
  @apply bg-section-about;
}
.circled-icon-hover-bg-section-exps:hover {
  @apply bg-section-exps;
}
.circled-icon-hover-bg-section-skills:hover {
  @apply bg-section-skills;
}
.circled-icon-hover-bg-section-projects:hover {
  @apply bg-section-projects;
}
.circled-icon-hover-bg-section-passions:hover {
  @apply bg-section-passions;
}
.circled-icon-hover-bg-section-contact:hover {
  @apply bg-section-contact;
}

/* Border color */

.circled-icon-border-current {
  @apply border-current;
}
.circled-icon-border-black {
  @apply border-black;
}
.circled-icon-border-white {
  @apply border-white;
}
.circled-icon-border-modal-header {
  @apply border-modal-header;
}
.circled-icon-border-modal-footer {
  @apply border-modal-footer;
}
.circled-icon-border-brand-facebook {
  @apply border-brand-facebook;
}
.circled-icon-border-brand-googleplus {
  @apply border-brand-googleplus;
}
.circled-icon-border-brand-linkedin {
  @apply border-brand-linkedin;
}
.circled-icon-border-brand-twitter {
  @apply border-brand-twitter;
}
.circled-icon-border-brand-github {
  @apply border-brand-github;
}
.circled-icon-border-brand-youtube {
  @apply border-brand-youtube;
}
.circled-icon-border-brand-react {
  @apply border-brand-react;
}
.circled-icon-border-brand-codrops {
  @apply border-brand-codrops;
}
.circled-icon-border-section-about {
  @apply border-section-about;
}
.circled-icon-border-section-exps {
  @apply border-section-exps;
}
.circled-icon-border-section-skills {
  @apply border-section-skills;
}
.circled-icon-border-section-projects {
  @apply border-section-projects;
}
.circled-icon-border-section-passions {
  @apply border-section-passions;
}
.circled-icon-border-section-contact {
  @apply border-section-contact;
}

/* Border type */

.circled-icon-border-solid {
  @apply border-solid;
}
.circled-icon-border-dashed {
  @apply border-dashed;
}